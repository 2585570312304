import * as actionTypes from '../actions/actionTypes'

const initialState = {
  uid: null,
  isAuthLoading: true
}

const authSignInUser = (state, action) => {
  return {
    ...state,
    uid: action.authResult.user.uid,
    username: action.authResult.user.displayName,
    email: action.authResult.user.email
  }
}

const authSuccess = (state, action) => {
  return {
    ...state,
    uid: action.uid,
    username: action.username,
    email: action.email,
    isAuthLoading: false
  }

}

const authLogout = (state) => {
  return {
    ...state,
    uid: null,
    isAuthLoading: false
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.AUTH_SIGN_IN_USER: return authSignInUser(state, action)
  case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
  case actionTypes.AUTH_LOGOUT: return authLogout(state, action)
  default: return state
  }
}

export default reducer
