import React, { useEffect } from 'react';
import { isEmpty } from 'lodash'
import { useLocation } from "react-router-dom";
import { connect } from 'react-redux'

import * as actions from '../../store/actions'
import TranslationProvider from './TranslationProvider'

const DefaultTranslationProvider = ({ children, setLocale, locale, loadLocale }) => {
  let browserLocale = navigator.language;
  let selectedLocale = null

  if (browserLocale) {
    selectedLocale = browserLocale.split("-")[0]
  }

  const search = useLocation().search;
  const qsLocale = new URLSearchParams(search).get('locale');

  if (!isEmpty(locale)) {
    selectedLocale = locale
  }

  if (!isEmpty(qsLocale)) {
    selectedLocale = qsLocale
  }

  useEffect(() => {
    if (!isEmpty(qsLocale)) {
      setLocale(qsLocale)
    }
  }, [qsLocale, setLocale])

  useEffect(() => {
    loadLocale()
  }, [])

  return (
    <TranslationProvider locale={selectedLocale}>{children}</TranslationProvider>
  )
}

const mapStateToProp = state => {
  return {
    locale: state.translations.locale
  }
}

const mapActionsToProps = dispach => {
  return {
    setLocale: (locale) => dispach(actions.translationsSetLocale(locale)),
    loadLocale: () => dispach(actions.translationsLoadLocale()),
  }
}

export default connect(mapStateToProp, mapActionsToProps)(DefaultTranslationProvider);
