import React from 'react';
import { useIntl } from 'react-intl';

const withFormatMessage = (Cmp) => {
  return (props) => {
    const { formatMessage } = useIntl()
    return <Cmp {...props} formatMessage={formatMessage} />
  }
}

export default withFormatMessage
