import React, { useState } from 'react'
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { isEmpty } from 'lodash'
import { useIntl } from 'react-intl';

import './NewScene.css'

export default function NewScene(props) {
  const [ name, setName ] = useState("")
  const [ hint, setHint] = useState(null)
  const { onClose, open, onCreate, onEdit, editScene } = props;
  const { formatMessage } = useIntl()

  const handleClose = () => {
    onClose();
  };

  const label = props.editScene ? formatMessage({id:'scenes.new_scene_edit_scene'}) : formatMessage({id:'scenes.new_scene_add_scene'})

  const handleOnClick = (name) => {
    if (isEmpty(name)) {
      setHint(formatMessage({id:'scenes.scene_name_cannot_be_empty'}))
      return
    } else {
      setHint(null)
    }

    if (editScene) {
      onEdit(name, editScene.playId, editScene.id)
    } else {
      onCreate(name)
    }
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{formatMessage({id:'scenes.new_scene_title'})}</DialogTitle>
      <div className="NewScene-Dialog-Body">
        <TextField
          defaultValue={editScene ? editScene.name : null}
          id="scene-new"
          label={formatMessage({id:'scenes.new_scene_scene_name'})}
          variant="outlined"
          onChange={(e) => setName(e.target.value)} style={{width:400}}
          onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleOnClick(name)
              }
            }}
          helperText={hint}
          error={!isEmpty(hint)}
        />

        <div className="NewScene-Dialog-Buttons">
          <Stack spacing={2} direction="row" justifyContent="center">
            <Button variant="outlined" onClick={onClose}>{formatMessage({id:'scenes.new_scene_cancel'})}</Button>
            <Button variant="contained" onClick={() => handleOnClick(name)}>{label}</Button>
          </Stack>
        </div>
      </div>
    </Dialog>
  );
}

NewScene.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
