import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { Link } from 'react-router-dom';
import { isNull } from 'lodash'
import { useIntl } from 'react-intl';

import SceneModel from '../../models/scene'
import './SceneEntry.css'

const SceneEntry = ({ scene, onEditSceneSelect, onDeleteSceneSelect }) => {
  const { formatMessage } = useIntl()
  const sceneModel = new SceneModel(scene)
  const formatedTime = sceneModel.formatedTime()
  const characterCount = sceneModel.characterCount()

  return (<div className="SceneEntry p-relative">
    <div className="SceneEntry-Text"><Link to={`/scripts/${scene.playId}/scenes/${scene.id}`}>{scene.name}</Link></div>
    <div className="SceneEntry-Chips">
      <Stack spacing={2} direction="row">
        {!isNull(formatedTime) && <Chip label={`${formatMessage({id:'scenes.time'})}: ${formatedTime}`} />}
        {!isNull(characterCount) && <Chip label={`${formatMessage({id:'scenes.characters'})}: ${characterCount}`} />}
      </Stack>
    </div>
    <div className="buttons-pull-right">
      <Stack spacing={2} direction="row" justifyContent="center">
        <Button variant="outlined" onClick={() => onEditSceneSelect(scene)} >{formatMessage({id:'scenes.edit_scene'})}</Button>
        <Button variant="outlined" color="error" onClick={() => onDeleteSceneSelect(scene)} >{formatMessage({id:'scenes.delete_scene'})}</Button>
      </Stack>
    </div>
  </div>)
}

export default SceneEntry;
