import * as actionTypes from '../actions/actionTypes'

const initialState = {
  locale: null
}

const translationsLoadLocale = (state, action) =>{
  return  {
    ...state,
    locale: action.locale
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.TRANSLATIONS_LOAD_LOCALE: return translationsLoadLocale(state, action)
  default: return state
  }
}

export default reducer
