import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import './PlayEntry.css'

const PlayEntry = ({ play, onDeletePlay, onEditPlay }) => {
  const { formatMessage } = useIntl()

  return (<div className="PlayEntry">
    <div className="PlayEntry-Text"><Link to={`/scripts/${play.id}`}>{play.name}</Link></div>
    <div>
      {play.scenes && <Chip label={`${formatMessage({id:'global.scenes'})}: ${play.scenes.length}`} />}
    </div>
    <div className="buttons-pull-right">
      <Stack spacing={2} direction="row" justifyContent="center">
        <Button variant="outlined" onClick={() => onEditPlay(play)}>{formatMessage({id:'scripts.edit_script'})}</Button>
        <Button variant="outlined" color="error" onClick={() => onDeletePlay(play)} >{formatMessage({id:'scripts.delete_script'})}</Button>
      </Stack>
    </div>
  </div>)
}

export default PlayEntry;
