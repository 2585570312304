import { omit } from 'lodash'
import { db, toEntries, toData } from '../config/firebase'

const createScene = (uid, playId, name) => {
  return db.collection(`accounts/${uid}/plays/${playId}/scenes`)
          .doc()
          .set({ name: name, playId: playId })
          .then(() => {
            return fetchScenesByPlay(uid, playId)
          })
}

const fetchScenesByPlay = (uid, playId) => {
  return db.collection(`accounts/${uid}/plays/${playId}/scenes`)
  .get()
  .then(toEntries)
}

const fetchScene = (uid, playId, sceneId) => {
  return db.collection(`accounts/${uid}/plays/${playId}/scenes`)
  .doc(sceneId)
  .get()
  .then(toData)
}

const updateScene = (uid, playId, sceneId, attributes) => {
  return db.collection(`accounts/${uid}/plays/${playId}/scenes`)
  .doc(sceneId)
  .set(omit(attributes, ['id']), {merge: true})
  .then(() =>{
    return fetchScene(uid, playId, sceneId)
  })
}

const deleteScene = (uid, scene) => {
  return db.collection(`accounts/${uid}/plays/${scene.playId}/scenes`)
    .doc(scene.id)
    .delete()
    .then(() => {
      return scene
    })
}

const service = {
  createScene,
  fetchScenesByPlay,
  fetchScene,
  updateScene,
  deleteScene
}

export default service
