import React, { useEffect } from 'react'
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { connect } from 'react-redux'
import Feedback from "feeder-react-feedback"; // import Feedback component
import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet

import * as actions from './store/actions'
import Scene from './containers/Scene/Scene'
import Play from './containers/Play/Play'
import Plays from './containers/Plays/Plays'
import Auth from './containers/Auth/Auth'
import DefaultTranslationProvider from './components/TranslationProvider/DefaultTranslationProvider'

const FEEDER_APP_ID = "61bcc7fadefe42000473546e"

function App({ initSignInUser, uid, isAuth, isAuthLoading, email }) {
  useEffect(() => {
    initSignInUser()
  }, [initSignInUser])

  let routes = null
  if (!isAuthLoading) {
    if (isAuth) {
      routes = (
        <Routes>
          <Route path='/scripts/:playId/scenes/:id' element={<Scene />} />
          <Route path='/scripts/:id' element={<Play />} />
          <Route path='/' element={<Plays />} />
        </Routes>
      )
    } else {
      routes = (
        <Routes>
          <Route path='/' element={<Auth />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>)
    }
  } else {
    routes = null
  }

  return (
    <div className="App">
        <Router>
          <DefaultTranslationProvider>
            {routes}
            {/*<Feedback projectId={FEEDER_APP_ID} emailDefaultValue={email} />*/}
          </DefaultTranslationProvider>
        </Router>
    </div>
  );
}

const mapStateToProp = state => {
  return {
    uid: state.auth.uid,
    email: state.auth.email,
    isAuth: state.auth.uid !== null,
    isAuthLoading: state.auth.isAuthLoading,
  }
}

const mapActionsToProps = dispach => {
  return {
    initSignInUser: () => dispach(actions.authInitSignInUser())
  }
}

export default connect(mapStateToProp, mapActionsToProps)(App);
