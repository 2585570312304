import * as actionTypes from './actionTypes'

import playsService from '../../services/plays'

export const playsOnLoadPlays = (uid) => dispach => {
  dispach(playsSetValue('loading', true))
  playsService.fetchPlays(uid)
  .then( plays => {
    dispach(playsSetValue('plays', plays))
    dispach(playsSetValue('loading', false))
  })
}

export const playsOnNewPlayDialogOpen = () => {
  return {
    type: actionTypes.PLAYS_ON_NEW_PLAY_DIALOG_OPEN,
  }
}

export const playsOnNewPlayDialogClose = () => {
  return {
    type: actionTypes.PLAYS_ON_NEW_PLAY_DIALOG_CLOSE,
  }
}

const playsSetValue = (name, value) => {
  return {
    type: actionTypes.PLAYS_SET_VALUE,
    name: name,
    value: value
  }
}

export const playsOnNewPlayDialogCreate = (uid, name) => dispach => {
  playsService.createPlay(uid, name)
  .then( plays => {
    dispach(playsSetValue('plays', plays))
    dispach(playsOnNewPlayDialogClose())
  })
}

export const playsOnEditPlay  = (play) => {
  return {
    type: actionTypes.PLAYS_ON_EDIT_PLAY,
    play: play
  }
}

export const playsOnDeletePlay = (uid, play) => dispatch => {
  playsService.deletePlay(uid, play)
  .then(() =>{
    dispatch(playsOnLoadPlays(uid))
  })
}

export const playsOnUpdatePlay = (uid, play, attributes) => dispach => {
  playsService.updatePlay(uid, play, attributes)
  .then(() => {
    dispach(playsOnNewPlayDialogClose())
    dispach(playsOnLoadPlays(uid))
  })
}
