import React from 'react'
import { useParams } from "react-router-dom";

export default function withParams(Cmp) {
  const Wrapper = (props) => {
    const params = useParams();

    return (
      <Cmp
        params={params}
        {...props}
      />
    )
  }

  return Wrapper
}
