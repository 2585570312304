import React from 'react'
import { useNavigate } from "react-router-dom";

export default function withParams(Cmp) {
  const Wrapper = (props) => {
    const navigate = useNavigate();

    return (
      <Cmp
        navigate={navigate}
        {...props}
      />
    )
  }

  return Wrapper
}
