import * as actionTypes from './actionTypes'

import playsService from '../../services/plays'
import scenesService from '../../services/scenes'

const playSetValue = (name, value) => {
  return  {
    type: actionTypes.PLAY_SET_VALUE,
    name: name,
    value: value
  }
}

export const playOnLoadPlay = (uid, playId) => dispach => {
  dispach(playSetValue('loading', true))
  playsService.fetchPlay(uid, playId)
  .then(play => {
    dispach(playSetValue('play', play))
    dispach(playSetValue('loading', false))
  })
}

export const playOnLoadScenesForPlay = (uid, playId) => dispach => {
  // .then( scenes => {
  //   const p = scenes.map(scene => {
  //     return scenesService.fetchScene(uid, playId, scene.id)
  //   })
  //
  //   return Promise.all(p)
  // })
  dispach(playSetValue('loading', true))
  scenesService.fetchScenesByPlay(uid, playId)
  .then(scenes => {
    dispach(playSetValue('scenes', scenes))
    dispach(playSetValue('loading', false))
  })
}

export const playOnNewSceneDialogOpen = () => {
  return {
    type: actionTypes.PLAY_ON_NEW_SCENE_DIALOG_OPEN,
  }
}

export const playOnNewSceneDialogClose = () => {
  return {
    type: actionTypes.PLAY_ON_NEW_SCENE_DIALOG_CLOSE,
  }
}

export const playOnNewSceneDialogCreate = (uid, playId, name) => dispach => {
  dispach(playSetValue('loading', true))
  scenesService.createScene(uid, playId, name)
  .then( scenes => {
    dispach(playSetValue('scenes', scenes))
    dispach(playOnNewSceneDialogClose())
    dispach(playSetValue('loading', false))
  })
}

export const playOnEditSceneSelect = (scene) => {
  return {
    type: actionTypes.PLAY_ON_EDIT_SCENE_SELECT,
    scene: scene
  }
}

export const playOnEditSceneDialog = (uid, playId, sceneId, name) => dispach => {
  dispach(playSetValue('loading', true))
  scenesService.updateScene(uid, playId, sceneId, { name: name })
  .then( scene => {
    dispach(playOnNewSceneDialogClose())
    dispach(playSetValue('loading', false))
  })
}

export const playOnDeleteSceneSelect = (uid, scene) => dispach => {
  dispach(playSetValue('loading', true))
  scenesService.deleteScene(uid, scene)
  .then( scene => {
    dispach(playOnNewSceneDialogClose())
    dispach(playSetValue('loading', false))
  })
}
