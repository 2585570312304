import React, { useState, useEffect } from 'react';
import { trim, lowerCase, findIndex, size, sortBy, last, includes, filter } from 'lodash'
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';

import './Line.css'

const CHART_PLACEHOLDER = "_"

const calculateHints = (text) => {
  return splitWords(text).length + 1
}

const splitWords = (line) => {
  return line.split(' ').filter(w => w !== '')
}

const Dots = ({ line, hintCount, onHint, hintPosition, hintWords }) => {
  const { formatMessage } = useIntl()
  const [reveal, setReveal ] = useState(false)
  let output = "......................"
  let hintWord = null
  const isHintDisabled = hintPosition >= hintCount

  if (hintPosition === 1) {
     output = splitWords(line).map(words => {
       return words.charAt(0) + CHART_PLACEHOLDER.repeat(words.length - 1)
     }).join(" ")
  } else if (hintPosition >= 1) {
    const words = splitWords(line)
    const wordsWithSize = words.map( w => [w, size(w)])
    const filteredWord = filter(wordsWithSize, w => !includes(hintWords, w[0]))
    const largestWord = last(
                          sortBy(filteredWord, w => w[1] ))

    const idx = findIndex(words, w => w === largestWord[0])

    output = words.map(words => {
      return words.charAt(0) + CHART_PLACEHOLDER.repeat(words.length - 1)
    })

    hintWords.forEach(hw => {
      const idx = findIndex(words, w => w === hw)

      output[idx] = words[idx]
    })

    output[idx] = largestWord[0]
    hintWord  = largestWord[0]
    output = output.join(" ")
  }

  if (reveal) {
    output = line
  }

  return (
    <div style={{margin:10, position:'relative'}}>
      <div>{output}</div>
      <div style={{marginTop:10}}>
        <Button disabled={isHintDisabled} onClick={() => onHint(hintPosition + 1, hintWord)}>{formatMessage({id:"scenes.hints"})}({hintCount-hintPosition})</Button>
        <Button onClick={() => setReveal(true)}>{formatMessage({id:"scenes.reveal"})}</Button>
      </div>
    </div>
  )
}

function getColor(stringInput) {
    let stringUniqueHash = [...stringInput].reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
}

const Line = ({ line, character, removeSymbols }) => {
  const [hintCount, setHintCount] = useState(0)
  const [hintPosition, setHintPosition] = useState(0)
  const [hintWords, setHintWords] = useState([])
  const parts = line.split('-')
  const currentCharacter = parts[0]
  const text = removeSymbols ? lowerCase(parts[1]) : parts[1]
  const isCharacter = trim(character) === trim(currentCharacter)
  const color = getColor(currentCharacter)

  useEffect(() => {
    setHintCount(calculateHints(text))
    setHintPosition(0)
  }, [text])

  const handleOnHint = (pos, word) => {
    if (pos <= hintCount) {
      setHintPosition(pos)

      if (word) {
        const newHintWords = [...hintWords]

        newHintWords.push(word)
        setHintWords(newHintWords)
      }
    }
  }

  return (
    <div className="Line" style={{borderColor: color }}>
      <div className="Line-Character" style={{color: color}}><h1>{currentCharacter}</h1></div>
      <div className="Line-Text">{isCharacter ? <Dots line={text} hintCount={hintCount} onHint={handleOnHint} hintPosition={hintPosition} hintWords={hintWords} /> : <h2>{text}</h2>}</div>
    </div>
  )
}

export default Line;
