import React from 'react'
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import Button from '@mui/material/Button';

export default function Footer() {
    return (
        <AppBar position="fixed" sx={{ xs:'none', top: 'auto', bottom: 0 }} color="primary">
          <Container maxWidth="xl">
            <Toolbar>
              <Typography variant="body1" color="inherit">
                © 2020 memorizeyourlines.com
              </Typography>
              <Button startIcon={<FacebookIcon />}>facebook</Button>
            </Toolbar>
          </Container>
        </AppBar>
    )
}
