import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import { uniq, map, lowerCase } from 'lodash'
import { useIntl } from 'react-intl';

import { splitLines, cleanLines, removeSymbolsFromLine }  from '../../utils/strings'
import './Learn.css'

function getCharacters(lines) {
  const allcharacters = lines.map(line => {
    return line.split('-')
  }).map(parts => {
    return parts[0]
  })

  return uniq(allcharacters)
}

function splitChuncks(inputArray, perChunk) {
  const result = inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index/perChunk)

    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  return result
}

const CHUNK_OPTIONS = [2,3,4,5,6]

const Learn = ({scene}) => {
  const [character, setCharacter ] = useState("")
  const [perChunk, setPerChunk ] = useState(3)
  const [removeSymbols, setRemoveSymbols] = useState(false)
  const lines = cleanLines(splitLines(scene.text))
  const chuncks = splitChuncks(lines, parseInt(perChunk))
  const characters = getCharacters(lines)
  const { formatMessage } = useIntl()

  return (
    <div>
      <Stack spacing={2} direction={{ xs: 'column', sm: 'column', md:'row' }} justifyContent="center">
        <FormControl style={{ width: 300, marginRight: 10}}>
          <InputLabel id="character-select-label">{formatMessage({id:"scenes.character"})}</InputLabel>
          <Select
            labelId="character-select-label"
            id="character-select"
            value={character}
            label={formatMessage({id:"scenes.character"})}
            onChange={(e) => setCharacter(e.target.value)}
          >
            {characters.map(c => {
              return <MenuItem key={c} value={c}>{c}</MenuItem>
            })}
          </Select>
        </FormControl>

        <FormControl style={{ width: 300}}>
          <InputLabel id="chunks-select-label">{formatMessage({id:"scenes.chunks"})}</InputLabel>
          <Select
            labelId="chunks-select-label"
            id="chunks-select"
            value={perChunk}
            label={formatMessage({id:"scenes.chunks"})}
            onChange={(e) => setPerChunk(e.target.value)}
          >
            {CHUNK_OPTIONS.map(c => {
              return <MenuItem key={c} value={c}>{c}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Stack>
      <div>
        <Checkbox onChange={(e) => setRemoveSymbols(e.target.checked)}/>
        <label>{formatMessage({id:"scenes.remove_symbols"})} (?!.;:) </label>
      </div>
      {map(chuncks, (chunk, idx) => {
        return (
          <div key={`${idx}-${chunk.length}`} className="Learn-Chunk">
            {map(chunk, (line, idx) =>  {
              const parts = line.split("-")
              const isCharacter = parts[0] === character
              const classes =  ['Learn-Chunk-Line']

              if (isCharacter) {
                classes.push('Learn-Chunk-IsCharacter')
              }

              let output = parts[1]

              if (removeSymbols) {
                output = lowerCase(removeSymbolsFromLine(output))
              }

              return (
                <div key={`line-${idx}`} className={classes.join(' ')}>
                  <span className="Learn-Character">{parts[0]}</span>
                  <span className="Learn-Line">{output}</span>
                </div>
              )
            })}
          </div>)
      })}
    </div>
  )
}

export default Learn;
