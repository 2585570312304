import React, { Component } from 'react';
import { connect } from 'react-redux'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import { isEmpty } from 'lodash'

import * as actions from '../../store/actions'
import SceneEntry from '../../components/SceneEntry/SceneEntry'
import NewScene from '../../components/NewScene/NewScene'
import AlertDialog from '../../components/AlertDialog/AlertDialog'
import { withLayout } from '../../hoc/Layout/Layout'
import  withParams from '../../hoc/withParams'
import  withLoading from '../../hoc/withLoading'
import  withFormatMessage from '../../hoc/withFormatMessage'

import './Play.css'

class Play extends Component {

  constructor(props) {
    super(props)
    this.state = { confirmDelete: false }
  }

  componentDidMount() {
    this.props.onLoadPlay(this.props.uid, this.props.params.id)
    this.props.onLoadScenesForPlay(this.props.uid, this.props.params.id)
  }

  onEditSceneHandler = (name, playId, sceneId) => {
    this.props.onEditSceneDialog(this.props.uid, playId, sceneId, name)
    this.props.onLoadScenesForPlay(this.props.uid, this.props.params.id)
  }

  onOpenConfirmDelete = (scene) => {
    this.setState({confirmDelete: true, scene: scene })
  }

  onDeleteSceneHandler = (scene) => {
    this.props.onDeleteSceneSelect(this.props.uid, scene)
    this.props.onLoadScenesForPlay(this.props.uid, this.props.params.id)
    this.setState({ confirmDelete: false })
  }

  render() {
    const {
      uid,
      scenes,
      play,
      newSceneDialogOpen,
      onNewSceneDialogCreate,
      onNewSceneDialogOpen,
      onNewSceneDialogClose,
      onEditSceneSelect,
      editScene,
      loading,
      formatMessage
    } = this.props

    if (loading) {
      return null
    }

    return (
      <Grid container justifyContent='center'>
        <Grid item xs={12} sm={12} md={6} style={{padding:10}}>
          <h1>{formatMessage({id: 'global.script'})} "{play && play.name}"</h1>
          <Stack spacing={2} direction="row" justifyContent="center">
            <Button variant="contained" onClick={onNewSceneDialogOpen}>{formatMessage({id: 'scenes.add_scene'})}</Button>
          </Stack>

          { !isEmpty(scenes) && (
          <div className="Play-Scenes">
            <ul className="Play-Scenes-List List">
              {scenes.map(scene => {
                return (
                  <li
                    key={scene.id}>
                    <Grow in={true}>
                      <div>
                        <SceneEntry
                          scene={scene}
                          onDeleteSceneSelect={(scene)=> this.onOpenConfirmDelete(scene)}
                          onEditSceneSelect={(scene) => onEditSceneSelect(scene)}
                          />
                      </div>
                    </Grow>
                  </li>
                )
              })}
            </ul>
          </div>
          )}
          <NewScene
            editScene={editScene}
            open={newSceneDialogOpen}
            onClose={onNewSceneDialogClose}
            onEdit={(name, playId, sceneId) => this.onEditSceneHandler(name, playId, sceneId)}
            onCreate={(name) => onNewSceneDialogCreate(uid, play.id, name)}
            />

            <AlertDialog
              title={formatMessage({id: 'scenes.delete_scene'})}
              content={formatMessage({id:'scenes.delete_confirmation'},{name:`"${this.state.scene && this.state.scene.name}"?`})}
              open={this.state.confirmDelete}
              onClose={() => this.setState({ confirmDelete: false })}
              onOk={() => this.onDeleteSceneHandler(this.state.scene)}
            />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProp = state => {
  return {
    uid:state.auth.uid,
    play: state.play.play,
    scenes: state.play.scenes,
    newSceneDialogOpen: state.play.newSceneDialogOpen,
    editScene: state.play.editScene,
    loading: state.play.loading
  }
}

const mapActionsToProps = dispach => {
  return {
    onLoadPlay: (uid, playId) => dispach(actions.playOnLoadPlay(uid, playId)),
    onLoadScenesForPlay: (uid, playId) => dispach(actions.playOnLoadScenesForPlay(uid, playId)),
    onNewSceneDialogOpen: () => dispach(actions.playOnNewSceneDialogOpen()),
    onNewSceneDialogClose: () => dispach(actions.playOnNewSceneDialogClose()),
    onNewSceneDialogCreate: (uid, playId, name) => dispach(actions.playOnNewSceneDialogCreate(uid, playId, name)),
    onEditSceneSelect: (scene) => dispach(actions.playOnEditSceneSelect(scene)),
    onEditSceneDialog: (uid, playId, sceneId, name) => dispach(actions.playOnEditSceneDialog(uid, playId, sceneId, name)),
    onDeleteSceneSelect: (uid, scene) => dispach(actions.playOnDeleteSceneSelect(uid, scene))
  }
}


export default connect(mapStateToProp, mapActionsToProps)(withLayout(withParams(withLoading(withFormatMessage(Play)))));
