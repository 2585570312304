import * as actionTypes from '../actions/actionTypes'

const initialState = {
  newPlayDialogOpen: false
}

const playsOnNewPlayDialogOpen = (state, action) => {
  return {
    ...state,
    newPlayDialogOpen: true,
    editPlay: null
  }
}

const playsOnNewPlayDialogClose = (state, action) => {
  return {
    ...state,
    newPlayDialogOpen: false,
    editPlay: null
  }
}

const playsSetValue = (state, action) => {
  return  {
    ...state,
    [action.name]: action.value
  }
}

const playOnEditPlay = (state, action) => {
  return {
    ...state, 
    editPlay: action.play,
    newPlayDialogOpen: true
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.PLAYS_ON_NEW_PLAY_DIALOG_OPEN: return playsOnNewPlayDialogOpen(state, action)
  case actionTypes.PLAYS_ON_NEW_PLAY_DIALOG_CLOSE: return playsOnNewPlayDialogClose(state, action)
  case actionTypes.PLAYS_SET_VALUE: return playsSetValue(state, action)
  case actionTypes.PLAYS_ON_EDIT_PLAY: return playOnEditPlay(state, action)
  default: return state
  }
}

export default reducer
