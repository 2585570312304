import * as actionTypes from '../actions/actionTypes'

const initialState = {}

const sceneSetValue = (state, action) => {
  return  {
    ...state,
    [action.name]: action.value
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.SCENE_SET_VALUE: return sceneSetValue(state, action)
  default: return state
  }
}

export default reducer
