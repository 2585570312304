import { db } from '../config/firebase'

const markSignIn = (uid, displayName) => {
  const timestamp = window.firebase.firestore.Timestamp.fromDate(new Date())
  return db.collection(`accounts`)
  .doc(uid)
  .set({
    lastLoggedIn: timestamp,
    displayName: displayName
  }, { merge: true })
}

const service = {
  markSignIn
}

export default service
