import { uniq, isEmpty } from 'lodash'
import { splitLines, cleanLines, removeSymbolsFromLines }  from '../utils/strings'

function wordCount(text = '') {
  return text.split(/\S+/).length - 1;
};

function wordTime(text) {
  return wordCount(text) / 130
}

function minTommss(minutes){
 var sign = minutes < 0 ? "-" : "";
 var min = Math.floor(Math.abs(minutes));
 var sec = Math.floor((Math.abs(minutes) * 60) % 60);
 return sign + (min < 10 ? "0" : "") + min + " min " + (sec < 10 ? "0" : "") + sec + " sec";
}

function getCharacters(lines) {
  const allcharacters = lines.map(line => {
    return line.split('-')
  }).map(parts => {
    return parts[0]
  })

  return uniq(allcharacters)
}

class Scene  {

  constructor(scene) {
    this.scene = scene
  }

  lines() {
    return cleanLines(splitLines(this.scene.text))
  }

  linesWithSymbols() {
    return removeSymbolsFromLines(this.lines())
  }

  wordCount() {
    return wordCount(this.scene.text)
  }

  wordTime() {
    return wordTime(this.scene.text)
  }

  formatedTime() {
    if (isEmpty(this.scene.text)) {
      return null
    }    
    return minTommss(this.wordTime())
  }

  getCharacters() {
    if (isEmpty(this.scene.text)) {
      return null
    }
    return getCharacters(this.lines())
  }

  characterCount() {
    if (isEmpty(this.scene.text)) {
      return null
    }
    return this.getCharacters().length
  }

}

export default Scene
