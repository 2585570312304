import React from 'react';
import { useIntl } from 'react-intl';
import sanitizeHtml from 'sanitize-html';

import './SceneFormat.css'


const SceneFormat = () => {
  const { formatMessage } = useIntl()

  return (<div className="SceneFormat">
    {formatMessage({id: 'scenes.text.format'})}

     <div className="SceneFormat-Text">
      <pre>
        <div dangerouslySetInnerHTML={{__html: formatMessage({id: 'scenes.text.script'})}} />
      </pre>
      <div dangerouslySetInnerHTML={{__html: formatMessage({id: 'scenes.text.info2'}, { e:(...chunks) => sanitizeHtml(`<b>${chunks.join(' ')}</b>`)})}} />
     </div>
  </div>)
}

export default SceneFormat;
