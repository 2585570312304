import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
// import FirebaseUIAuth from "../../components/FirebaseUIAuth/FirebaseUIAuth";
import { connect } from 'react-redux'
import { isEmpty, upperCase } from 'lodash'

import * as actions from '../../store/actions'
import withNavigate from '../../hoc/withNavigate'
import sampleUIen from './sampleUI-en.png'
import sampleUIes from './sampleUI-es.png'
import logo from './logo.png'
import './Auth.css'
import  withFormatMessage from '../../hoc/withFormatMessage'
import { LOCALES } from '../../components/TranslationProvider/TranslationProvider'
// import { auth } from '../../config/firebase'

class Auth extends Component {

  constructor(props) {
    super(props)
    this.state = { localeAnchor: null }
  }

  componentDidMount() {
    const uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          window.firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          window.firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: '/terms.html',
        // Privacy policy url/callback.
        privacyPolicyUrl: function() {
          window.location.assign('/privacy.html');
        },
        callbacks: {
          signInSuccessWithAuthResult: (result) => {
            console.log('redirect')
            this.props.onSignInUser(result)
            this.props.navigate('/')
          },
          uiShown: function() {
            console.log('uiShown')
          }
        }
      };

    this.ui = new window.firebaseui.auth.AuthUI(window.firebase.auth());
      // The start method will wait until the DOM is loaded.
    this.ui.start('#firebaseui-auth-container', uiConfig);
  }

  componentWillUnmount() {
    this.ui && this.ui.delete()
  }

  handleOpenLocale = (e) => {
    this.setState({ localeAnchor: e.currentTarget })
  }

  handleSelectLocale = (newLocale) => () => {
    this.setState({ localeAnchor: null })
    this.props.setLocale(newLocale)
    window.location.reload()
  }

  render() {
    const { localeAnchor } = this.state
    const { formatMessage, locale } = this.props
    const defaultLocale = isEmpty(locale) ? 'en' : locale

    let sampleUI = null

    if (defaultLocale === "en") {
      sampleUI = sampleUIen
    } else if (defaultLocale === "es") {
      sampleUI = sampleUIes
    } else {
      sampleUI = sampleUIen
    }

    // const uiConfig = {
    //     signInSuccessUrl: '/',
    //     signInOptions: [
    //       // Leave the lines as is for the providers you want to offer your users.
    //       window.firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    //       window.firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //     ],
    //     // tosUrl and privacyPolicyUrl accept either url string or a callback
    //     // function.
    //     // Terms of service url/callback.
    //     tosUrl: '/terms.html',
    //     // Privacy policy url/callback.
    //     privacyPolicyUrl: function() {
    //       window.location.assign('/privacy.html');
    //     },
    //     callbacks: {
    //       signInSuccessWithAuthResult: (result) => {
    //         console.log('signInSuccessWithAuthResult')
    //         this.props.onSignInUser(result)
    //         this.props.navigate('/')
    //       }
    //     }
    //   };

    return (
      <div className="Auth">
        <div className="Auth-Logo">
          <Button
            startIcon={<LanguageIcon />}
            onClick={this.handleOpenLocale}
            style={{float: 'right'}}
            sx={{ color: 'white', display:{ xs: 'flex', md:'flex', justifyContent:'right' }}}>
              {isEmpty(locale) ? "EN" : upperCase(locale)}
          </Button>
          <Menu
             id="menu-appbar"
             anchorEl={localeAnchor}
             anchorOrigin={{
               vertical: 'top',
               horizontal: 'right',
             }}
             keepMounted
             transformOrigin={{
               vertical: 'top',
               horizontal: 'right',
             }}
             open={Boolean(localeAnchor)}
             onClose={() => this.setState({localeAnchor: null})}
           >
             {LOCALES.map(locale => {
                return <MenuItem key={locale} onClick={this.handleSelectLocale(locale)}>{upperCase(locale)}</MenuItem>
             })}
           </Menu>
          <img src={logo} alt="logo" style={{height:300}}/>
        </div>
        <div id="firebaseui-auth-container"></div>
        {/*<FirebaseUIAuth
          lang={defaultLocale}
          version="4.7.3"
          config={uiConfig}
          auth={auth}
        />*/}

        <Grid container justifyContent='center'>
          <Grid item xs={12} sm={12} md={6} style={{padding:10}}>
            <div className="Auth-Text">
              <div dangerouslySetInnerHTML={{__html:formatMessage({id:'home.text1'})}} />
            </div>

            <div className="Auth-Text">
              <div dangerouslySetInnerHTML={{__html:formatMessage({id:'home.text2'})}} />
            </div>

            <div className="Auth-Text">
              <div dangerouslySetInnerHTML={{__html:formatMessage({id:'home.text3'})}} />
            </div>
          </Grid>
        </Grid>

        <Grid container justifyContent='center'>
          <Grid item xs={12} sm={12} md={6} style={{padding:10}}>
            <div className="Auth-SampleUI-Title">
              {formatMessage({id:'home.this_is_inside'})}
            </div>
            <div className="Auth-SampleUI">
              <img src={sampleUI} alt="sample ui" style={{width:"100%"}}/>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

}

const mapStateToProp = state => {
  return {
    isAuth: state.auth.uid !== null,
    locale: state.translations.locale
  }
}

const mapActionsToProps = dispach => {
  return {
    onSignInUser: (authResult) => dispach(actions.authSignInUserInit(authResult)),
    setLocale: (locale) => dispach(actions.translationsSetLocale(locale)),
    loadLocale: () => dispach(actions.translationsLoadLocale())
  }
}

export default connect(mapStateToProp,mapActionsToProps)(withNavigate(withFormatMessage(Auth)))
