import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function withLoading(Cmp) {
  return (props) => {    
    return (
      <>
        {props.loading && <CircularProgress />}
        <Cmp {...props} />
      </>
    )
  }
}
