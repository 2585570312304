import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import TextareaAutosize from '@mui/material/TextareaAutosize'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash'

import * as actions from '../../store/actions'
import Practice from '../../components/Practice/Practice'
import Stats from '../../components/Stats/Stats'
import Learn from '../../components/Learn/Learn'
import ValidateScene from '../../components/ValidateScene/ValidateScene'
import SceneFormat from '../../components/SceneFormat/SceneFormat'
import { splitLines, cleanLines }  from '../../utils/strings'
import useInterval from '../../hooks/useInterval'
import { withLayout } from '../../hoc/Layout/Layout'
import { useParams } from "react-router-dom";
import  withLoading from '../../hoc/withLoading'
import { useIntl } from 'react-intl';

const TIMER_STEP = 100

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs:1, sm: 1, md: 3 } }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Scene = ({uid, scene, onLoadScene, onSceneUpdate, onLoadPlay, play, loading }) => {
  const [value, setValue] = useState(0);
  const [position, setPosition] = useState(0)
  const [character, setCharacter] = useState("")
  const [isPaused, setPaused] = useState(true);
  const [seconds, setSeconds] = useState(3)
  const [timer, setTimer] = useState(0)
  const [removeSymbols, setRemoveSymbols] = useState(false)
  const { id, playId } = useParams()
  const { formatMessage } = useIntl()
  const lines = cleanLines(splitLines(scene ? scene.text : "" ))

  useEffect(() => {
    onLoadScene(uid, playId, id)
  }, [uid, id, onLoadScene, playId])

  useEffect(() => {
    if (scene) {
      onLoadPlay(uid, scene.playId)
    }
  }, [uid, scene, onLoadPlay])

  const updateSceneHandler =  (attributes) => {
    if (scene.text !== attributes.text) {
      onSceneUpdate(uid, playId, scene.id, attributes)
    }
  }

  useInterval(function() {
    if (timer < (seconds * 1000)) {
      const newTime = timer + TIMER_STEP
      setTimer(newTime)
    } else {
      setTimer(0)
      if (position < lines.length) {
        setPosition(position + 1)
      } else {
        // clearInterval(playIntervalRef.current)
        setPaused(true)
      }
    }
  }, isPaused ? null : TIMER_STEP)

  const progress = timer * 100 / (seconds * 1000)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onNextHandler = () => {
    if (position < (lines.length - 1)) {
      setPosition(position + 1)
    }
  }

  const onBackHandler = () => {
    if (position > 0 ) {
      setPosition(position - 1)
    }
  }

  const onCharacterChangeHandler = (event) => {
    setCharacter(event.target.value)
  }

  const onPlayHandler = () => {
    setPaused(false)
  }

  const onPauseHandler = () => {
    setPaused(true)
  }

  const onPlaySecondsChangeHandler = (event) => {
    setSeconds(parseInt(event.target.value))
  }

  const onRemoveSymbolsHandler = (event) => {
    setRemoveSymbols(event.target.checked)
  }

  const onPositionUpdateHanlder = (number) => {
    setPosition(number)
  }

  if (loading) {
    return null
  }

  if (isEmpty(scene)) {
    return (
      <div>
        <h1>No scene</h1>
      </div>
    )
  }

  const isNextDisabled = position >= (lines.length - 1)

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12} sm={12} md={6} style={{padding:10}}>
        <h1>
          {formatMessage({id:'global.script'})}  "{play && <Link to={`/scripts/${playId}`}>{play.name}</Link>}"
        </h1>
        <h1>{formatMessage({id:'global.scene'})} "{scene && scene.name}"</h1>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label={formatMessage({id:'scene.tab.text'})}   />
              <Tab label={formatMessage({id:'scene.tab.practice'})}/>
              <Tab label={formatMessage({id:'scene.tab.stats'})}/>
              <Tab label={formatMessage({id:'scene.tab.learn'})}/>
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ValidateScene scene={scene} />
            <SceneFormat />
            <TextareaAutosize
              minRows={5}
              style={{ width: '100%' }}
              defaultValue={scene.text}
              onBlur={(e) => updateSceneHandler({ text: e.target.value} )}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Practice
              text={scene.text}
              position={position}
              character={character}
              seconds={seconds}
              isPlaying={!isPaused}
              progress={progress}
              removeSymbols={removeSymbols}
              onNext={onNextHandler}
              onBack={onBackHandler}
              onCharacterChange={onCharacterChangeHandler}
              onPlay={onPlayHandler}
              onPause={onPauseHandler}
              onPlaySecondsChange={onPlaySecondsChangeHandler}
              onRemoveSymbols={onRemoveSymbolsHandler}
              onPositionUpdate={onPositionUpdateHanlder}
              isNextDisabled={isNextDisabled}
              />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Stats scene={scene} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Learn scene={scene} />
          </TabPanel>
       </Box>
     </Grid>
    </Grid>)
};

const mapStateToProp = state => {
  return {
    uid: state.auth.uid,
    scene: state.scene.scene,
    play: state.scene.play,
    loading: state.scene.loading
  }
}

const mapActionsToProps = dispach => {
  return {
    onLoadPlay: (uid, playId) =>  dispach(actions.sceneOnLoadPlay(uid, playId)),
    onLoadScene: (uid, playId, sceneId) => dispach(actions.sceneOnLoadScene(uid, playId, sceneId)),
    onSceneUpdate: (uid, playId, sceneId, attributes) => dispach(actions.sceneOnSceneUpdate(uid, playId, sceneId, attributes))
  }
}

export default connect(mapStateToProp, mapActionsToProps)(withLayout(withLoading(Scene)))
