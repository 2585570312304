import * as actionTypes from './actionTypes'

import scenesService from '../../services/scenes'
import playsService from '../../services/plays'

const sceneSetValue = (name, value) => {
  return  {
    type: actionTypes.SCENE_SET_VALUE,
    name: name,
    value: value
  }
}

export const sceneOnLoadScene = (uid, playId, sceneId) => dispach => {
  dispach(sceneSetValue('loading', true))
  scenesService.fetchScene(uid, playId, sceneId)
  .then(scene => {
    dispach(sceneSetValue('scene', scene))
    dispach(sceneSetValue('loading', false))
  })
}

export const sceneOnSceneUpdate = (uid, playId, sceneId, attributes) => dispach => {
  dispach(sceneSetValue('loading', true))
  scenesService.updateScene(uid, playId, sceneId, attributes)
  .then(scene => {
    dispach(sceneSetValue('scene', scene))
    dispach(sceneSetValue('loading', false))
  })
}

export const sceneOnLoadPlay = (uid, playId) => dispach => {
  dispach(sceneSetValue('loading', true))
  playsService.fetchPlay(uid, playId)
  .then(play => {
    dispach(sceneSetValue('play', play))
    dispach(sceneSetValue('loading', false))
  })
}
