import React from 'react';
import { filter, isEmpty, map } from 'lodash'
import Alert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { splitLines, cleanLines }  from '../../utils/strings'
import './ValidateScene.css'

const isValid = (text) => {
  return text.split("-").length === 2
}

const ValidateScene = ({ scene }) => {
  const lines = cleanLines(splitLines(scene.text))

  const validations = map(lines, (line, idx) => {
    if (isValid(line)) {
      return { line: line, valid: true, idx: idx }
    } else {
      return { line: line, valid: false, idx: idx }
    }
  })

  const errors = filter(validations, v => v.valid === false)

  if (isEmpty(errors)) {
    return null
  }

  return (
    <div style={{marginBottom:10}} className="ValidateScene">

      <Accordion className="ValidateSceneAccordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            backgroundColor: "rgb(253, 237, 237)"
          }}
        >
          <Alert severity="error">There are {errors.length} lines with errors. Those lines cannot be parsed.</Alert>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            {errors.map(error => <li>[{error.idx + 1}] {error.line}</li>)}
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default ValidateScene;
