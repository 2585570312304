import React, { Component } from 'react';
import { connect } from 'react-redux'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import { isEmpty } from 'lodash'

import * as actions from '../../store/actions'
import NewPlay from '../../components/NewPlay/NewPlay'
import PlayEntry from '../../components/PlayEntry/PlayEntry'
import AlertDialog from '../../components/AlertDialog/AlertDialog'
import { withLayout } from '../../hoc/Layout/Layout'
import  withLoading from '../../hoc/withLoading'
import  withFormatMessage from '../../hoc/withFormatMessage'
import FormattedMessage from '../../components/FormattedMessage/FormattedMessage'
import './Plays.css'


class Plays extends Component {

  constructor(props) {
    super(props)
    this.state = { confirmDelete: false }
  }

  componentDidMount() {
    this.props.onLoadPlays(this.props.uid)
  }

  handleDeletePlay = (play) => {
    this.props.onDeletePlay(this.props.uid, play)
    this.setState({ confirmDelete: false })
  }

  handlePlayUpdate = (play, attributes) => {
    this.props.onPlayUpdate(this.props.uid, play, attributes)
  }

  handleOpenDeleteConfirm = (play) => {
    this.setState({ confirmDelete: true, play: play })
  }

  render() {
    const {
      uid,
      plays,
      newPlayDialogOpen,
      onNewPlayDialogCreate,
      onNewPlayDialogOpen,
      onNewPlayDialogClose,
      onEditPlay,
      editPlay,
      loading,
      formatMessage
    } = this.props

    if (loading) {
      return null
    }

    return (
      <Grid container justifyContent='center'>
      <Grid item xs={12} sm={12} md={6} style={{padding:10}}>
          <h1><FormattedMessage id="global.scripts" /></h1>
          <Stack spacing={2} direction="row" justifyContent="center">
            <Button variant="contained" onClick={onNewPlayDialogOpen}><FormattedMessage id="scripts.add_script" /></Button>
          </Stack>

          { !isEmpty(plays) && (
            <div className="Plays-Plays">
              <ul className="Plays-Plays-List List">
                {plays.map(play => {
                  return (
                    <li key={play.id}>
                      <Grow in={true}>
                        <div>
                          <PlayEntry
                            play={play}
                            onEditPlay={onEditPlay}
                            onDeletePlay={this.handleOpenDeleteConfirm}
                          />
                        </div>
                      </Grow>
                      </li>
                  )
                })}
              </ul>
            </div>
            )
          }

          <NewPlay
            editPlay={editPlay}
            open={newPlayDialogOpen}
            onClose={onNewPlayDialogClose}
            onEdit={(name, play) => this.handlePlayUpdate(play, { name: name })}
            onCreate={(name) => onNewPlayDialogCreate(uid, name)}
          />

          <AlertDialog
            title={formatMessage({id:'scripts.delete_script_title'})}
            content={formatMessage({ id:'scripts.delete_confirmation'}, { name: `"${this.state.play && this.state.play.name}"?`})}
            open={this.state.confirmDelete}
            onClose={() => this.setState({ confirmDelete: false })}
            onOk={() => this.handleDeletePlay(this.state.play)}
          />
        </Grid>
      </Grid>
    );
  }

}

const mapStateToProp = state => {
  return {
    uid:state.auth.uid,
    plays: state.plays.plays,
    newPlayDialogOpen: state.plays.newPlayDialogOpen,
    editPlay: state.plays.editPlay,
    loading: state.plays.loading
  }
}

const mapActionsToProps = dispach => {
  return {
    onLoadPlays: (uid) => dispach(actions.playsOnLoadPlays(uid)),
    onNewPlayDialogOpen: () => dispach(actions.playsOnNewPlayDialogOpen()),
    onNewPlayDialogClose: () => dispach(actions.playsOnNewPlayDialogClose()),
    onNewPlayDialogCreate: (uid, name) => dispach(actions.playsOnNewPlayDialogCreate(uid, name)),
    onEditPlay: (play) => dispach(actions.playsOnEditPlay(play)),
    onDeletePlay: (uid, play) => dispach(actions.playsOnDeletePlay(uid, play)),
    onPlayUpdate: (uid, play, attributes) => dispach(actions.playsOnUpdatePlay(uid, play, attributes))
  }
}

export default connect(mapStateToProp, mapActionsToProps)(withLayout(withLoading(withFormatMessage(Plays))));
