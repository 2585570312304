import React from 'react';
import { IntlProvider } from 'react-intl'

import { keys } from 'lodash'

// import enCAMessages from "../../../../data/translations/en-US.json";
// import enGBMessages from "../../../../data/translations/en-US.json";
import enUSMessages from "../../data/translations/en-US.json";
import esESMessages from "../../data/translations/es-ES.json";

// const allMessages = {
//   "en-CA": enCAMessages,
//   "en-GB": enGBMessages,
//   "en-US": enUSMessages,
//   "es-ES": esESMessages,
// };

export const allMessages = {
  "en": enUSMessages,
  "es": esESMessages
}

export const LOCALES = keys(allMessages)

const TranslationProvider = ({ locale, children }) => {
  const messages = allMessages[locale.split("-")[0]];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}

export default TranslationProvider;
