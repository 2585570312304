// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCijC7nUXqnlU9LOXU2TX5mfSzyyJyBrWs",
  authDomain: "scripthelper-f1ff1.firebaseapp.com",
  projectId: "scripthelper-f1ff1",
  storageBucket: "scripthelper-f1ff1.appspot.com",
  messagingSenderId: "863288190559",
  appId: "1:863288190559:web:fb51ff9773c589cfae1dea",
  measurementId: "G-TJ07XG0D1N"
};

// Initialize Firebase
export const app = window.firebase.initializeApp(firebaseConfig)

export const db = window.firebase.firestore()

export const auth = window.firebase.auth()

// const analytics = getAnalytics(app);

export const toEntries = (querySnapshot) => {
  const entries = []
  querySnapshot.forEach(function(doc) {
     entries.push({ id: doc.id, ...doc.data()})
  })
  return entries
}

export const toData = (doc) => {
  return { id: doc.id, ...doc.data() }
}

export async function deleteCollection(db, collectionPath, batchSize) {
  const collectionRef = db.collection(collectionPath);
  const query = collectionRef.orderBy('__name__').limit(batchSize);

  return new Promise((resolve, reject) => {
    deleteQueryBatch(db, query, resolve).catch(reject);
  });
}

export async function deleteQueryBatch(db, query, resolve) {
  const snapshot = await query.get();

  const batchSize = snapshot.size;
  if (batchSize === 0) {
    // When there are no documents left, we are done
    resolve();
    return;
  }

  // Delete documents in a batch
  const batch = db.batch();
  snapshot.docs.forEach((doc) => {
    batch.delete(doc.ref);
  });
  await batch.commit();

  // Recurse on the next process tick, to avoid
  // exploding the stack.
  process.nextTick(() => {
    deleteQueryBatch(db, query, resolve);
  });
}
