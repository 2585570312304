import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { uniq, filter, isEmpty  } from 'lodash'
import { splitLines}  from '../../utils/strings'
import { useIntl } from 'react-intl';

import './Stats.css'

function wordCount(text = '') {
  return text.split(/\S+/).length - 1;
};

function wordTime(text) {
  return wordCount(text) / 130
}

function cleanLines(lines) {
  return filter(lines, line => !isEmpty(line))
}

function minTommss(minutes){
 var sign = minutes < 0 ? "-" : "";
 var min = Math.floor(Math.abs(minutes));
 var sec = Math.floor((Math.abs(minutes) * 60) % 60);
 return sign + (min < 10 ? "0" : "") + min + " min " + (sec < 10 ? "0" : "") + sec + " sec";
}

const Value = ({ label, value }) => {
  return (
    <>
    <Grid item>
      <Typography component='dt' variant='h6'>
        {label}
      </Typography>
      <Typography component='dd' variant='body2'>
        {value}
      </Typography>
    </Grid>
    </>
  )
}

function getCharacters(lines) {
  const allcharacters = lines.map(line => {
    return line.split('-')
  }).map(parts => {
    return parts[0]
  })

  return uniq(allcharacters)
}

const getTextWithCharacter = (text) => {
  const lines = cleanLines(splitLines(text))

  return lines.map( line => line.split("-")[1] ).join(" ")
}

const TimeForCharacter = ({character, lines}) => {
  const { formatMessage } = useIntl()
  const characterLines = filter(lines, line => {
    const parts = line.split("-")

    return parts[0] === character
  }).map(line => line.split("-")[1] )

  return (
    <div>
      {character}: {minTommss(wordTime(characterLines.join("")).toFixed(2))}/{characterLines.length} {formatMessage({id:'scenes.lines'})}
    </div>
  )
}

const Stats = ({ scene }) => {
  const time = wordTime(getTextWithCharacter(scene.text))
  const lines = cleanLines(splitLines(scene.text))
  const characters = getCharacters(lines)
  const { formatMessage } = useIntl()

  return (
    <div className="Stats">
      <Grid
        direction="column"
        container
        component='dl' // mount a Definition List
        spacing={2}>

        <Value label={formatMessage({id:'scenes.time'})} value={`${minTommss(time.toFixed(2))}`} />
        <Value label={formatMessage({id:'scenes.lines'})}  value={lines.length} />
        <Value label={formatMessage({id:'scenes.characters'})}  value={characters.join(',')} />
        <Value label={formatMessage({id:'scenes.time_by_character'})}  value={characters.map( c => <TimeForCharacter key={c} character={c} lines={lines} />)} />
      </Grid>
    </div>
  )
}

export default Stats;
