import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import LinearProgress from '@mui/material/LinearProgress';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import { uniq } from 'lodash'
import Line from './Line/Line'
import Grid from '@mui/material/Grid';
import { useIntl } from 'react-intl';
import "./Practice.css"
import { splitLines, cleanLines, removeSymbolsFromLines }  from '../../utils/strings'

function getCharacters(lines) {
  const allcharacters = lines.map(line => {
    return line.split('-')
  }).map(parts => {
    return parts[0]
  })

  return uniq(allcharacters)
}

const SECONDS = [3,5,10,15]

const Practice = ({
    text,
    position,
    onBack,
    onNext,
    character,
    onCharacterChange,
    onPlay,
    onPause,
    seconds,
    onPlaySecondsChange,
    isPlaying,
    progress,
    onRemoveSymbols,
    removeSymbols,
    onPositionUpdate,
    isNextDisabled
}) => {
  const { formatMessage } = useIntl()
  let lines = cleanLines(splitLines(text))

  const characters = getCharacters(lines)

  if (removeSymbols) {
    lines = removeSymbolsFromLines(lines)
  }

  return (
    <div className="Practice">
      <div className="Practice-Form">
        <div className="Practice-Character">
          <FormControl fullWidth>
            <InputLabel id="character-select-label">{formatMessage({id:'scenes.character'})}</InputLabel>
            <Select
              labelId="character-select-label"
              id="character-select"
              value={character}
              label="Character"
              onChange={onCharacterChange}
            >
              {characters.map(c => {
                return <MenuItem key={c} value={c}>{c}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
        <div>
          <Checkbox onChange={onRemoveSymbols}/>
          <label>{formatMessage({id:'scenes.remove_symbols'})} (?!.;:) </label>
        </div>
      </div>


      <Grid container justifyContent='center'>
        <Grid item xs={12} sm={12} md={10} style={{padding:10}}>
          <div className="Practice-LinearProgress">
            <div className="Practice-Position">{position + 1}/{lines.length}</div>
            <Slider
              value={position}
              min={0} max={lines.length-1}
              style={{width:'100%', margin:'auto'}}
              onChange={(e, number) => onPositionUpdate(number) }
              />
          </div>
          <Line line={lines[position]} character={character} removeSymbols={removeSymbols}/>
        </Grid>
      </Grid>

      { isPlaying &&
        <div className="Practice-LinearProgress">
          <LinearProgress variant="determinate" value={progress} style={{width:'50%', margin:'auto'}} />
        </div>
      }
      <div className="Practice-Buttons">
        <Stack spacing={2} direction={{ xs: 'column', sm: 'column', md:'row' }} justifyContent="center">
          <Button variant="contained" onClick={onBack}>{formatMessage({id:'scenes.back'})}</Button>
          <Button variant="contained" onClick={onNext} disabled={isNextDisabled}>{formatMessage({id:'scenes.next'})}</Button>
          <Button variant="contained" onClick={onPlay} disabled={isPlaying} >{formatMessage({id:'scenes.play'})}</Button>
          <Button variant="contained" onClick={onPause} disabled={!isPlaying}>{formatMessage({id:'scenes.pause'})}</Button>
          <FormControl style={{width:150}}>
            <InputLabel id="play-select-label">{formatMessage({id:'scenes.play_seconds'})}</InputLabel>
            <Select
              labelId="play-select-label"
              id="play-select"
              value={seconds}
              label="Play seconds"
              onChange={onPlaySecondsChange}
            >
              {SECONDS.map(c => {
                return <MenuItem key={c} value={c}>{c}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Stack>
      </div>
    </div>
  )
};

export default Practice;
