import * as actionTypes from '../actions/actionTypes'

const initialState = {
    newSceneDialogOpen: false
}

const playsOnNewSceneDialogOpen = (state, action) => {
  return  {
    ...state,
    editScene: null,
    newSceneDialogOpen: true
  }
}

const playOnNewSceneDialogClose = (state, action) => {
  return  {
    ...state,
    editScene: null,
    newSceneDialogOpen: false
  }
}

const playSetValue = (state, action) => {
  return  {
    ...state,
    [action.name]: action.value
  }
}

const playOnEditSceneSelect = (state, action) => {
  return  {
    ...state,
    editScene: action.scene,
    newSceneDialogOpen: true
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.PLAY_ON_NEW_SCENE_DIALOG_OPEN: return playsOnNewSceneDialogOpen(state, action)
  case actionTypes.PLAY_ON_NEW_SCENE_DIALOG_CLOSE: return playOnNewSceneDialogClose(state, action)
  case actionTypes.PLAY_SET_VALUE: return playSetValue(state, action)
  case actionTypes.PLAY_ON_EDIT_SCENE_SELECT: return playOnEditSceneSelect(state, action)
  default: return state
  }
}

export default reducer
