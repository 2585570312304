import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux'
import { lowerCase, upperCase, isEmpty } from 'lodash'
import { useIntl } from 'react-intl';

import FormattedMessage from '../../components/FormattedMessage/FormattedMessage'
import Footer from '../../components/Footer/Footer'
import * as actions from '../../store/actions'
import { LOCALES } from '../../components/TranslationProvider/TranslationProvider'

const pages = [[ 'Scripts', "/", <TextSnippetIcon />]];

const drawerWidth = 240;

const Layout = ({ children, email, onLogout, setLocale, locale }) => {
  let navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [localeAnchor, setLocaleAnchor ] = React.useState(null);
  const { formatMessage } = useIntl()

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  }

  const handleCloseNavMenu = (url) => {
    setAnchorElNav(null);
    navigate(url)
  }

  const handleOpenLocale = (e) => {
    setLocaleAnchor(e.currentTarget)
  }

  const handleSelectLocale = (newLocale) => () => {
    setLocaleAnchor(null)
    setLocale(newLocale)
  }

  return (
    <>
    <AppBar position="static">
    <Container maxWidth="xl">
      <Toolbar disableGutters>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
        >
          Memorize your lines
        </Typography>

        <Box sx={{display: { xs: '1', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>

          <Drawer
            variant="temporary"
            open={Boolean(anchorElNav)}
            onClose={() => setAnchorElNav(null)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
             display: { xs: 'block', sm: 'none' },
             '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          >
            <List>
              {pages.map( page => {
                return (<ListItem button key={page[0]} onClick={() => handleCloseNavMenu(page[1])}>
                          <ListItemIcon>{page[2]}</ListItemIcon>
                          <ListItemText primary={formatMessage({ id: `toolbar.${lowerCase(page[0])}` } )} />
                        </ListItem>)
              })}
              <ListItem button key="Logout" onClick={onLogout}>
                <ListItemIcon><LogoutIcon /></ListItemIcon>
                <ListItemText primary={formatMessage({ id: 'toolbar.logout'})} />
              </ListItem>
              <ListItem key={email}>
                <ListItemText primary={email} />
              </ListItem>
            </List>
          </Drawer>
        </Box>

        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
        >
          Script Helper
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {pages.map((page) => (
            <Button
              key={page[0]}
              onClick={() => handleCloseNavMenu(page[1])}
              sx={{ my: 2, color: 'white' }}
              startIcon={page[2]}
            >
              <FormattedMessage
                id={`toolbar.${lowerCase(page[0])}`}
              />
            </Button>
          ))}
        </Box>
        <Typography sx={{mr:2, display:{ xs: 'none', md:'block' }}}>{email}</Typography>
        <Button
          startIcon={<LanguageIcon />}
          onClick={handleOpenLocale}
          sx={{ color: 'white', display:{ xs: 'none', md:'flex' }}}>
            {isEmpty(locale) ? "EN" : upperCase(locale)}
        </Button>
        <Menu
           id="menu-appbar"
           anchorEl={localeAnchor}
           anchorOrigin={{
             vertical: 'top',
             horizontal: 'right',
           }}
           keepMounted
           transformOrigin={{
             vertical: 'top',
             horizontal: 'right',
           }}
           open={Boolean(localeAnchor)}
           onClose={() => setLocaleAnchor(null)}
         >
           {LOCALES.map(locale => {
              return <MenuItem key={locale} onClick={handleSelectLocale(locale)}>{upperCase(locale)}</MenuItem>
           })}
         </Menu>

        <Button sx={{ color: 'white', display:{ xs: 'none', md:'flex' }}} onClick={onLogout} startIcon={<LogoutIcon />}>
          {formatMessage({ id: 'toolbar.logout'})}
        </Button>
      </Toolbar>
    </Container>
  </AppBar>
  <div style={{marginBottom:50}}>
    {children}
  </div>
  <Footer/>
  </>
  );
}

const mapStateToProp = state => {
  return {
    email: state.auth.email,
    locale: state.translations.locale
  }
}

const mapActionsToProps = dispach => {
  return {
    onLogout: () => dispach(actions.logout()),
    setLocale: (locale) => dispach(actions.translationsSetLocale(locale))
  }
}

const LayoutRedux = connect(mapStateToProp,mapActionsToProps)(Layout)

export default LayoutRedux

export function withLayout(WrappedComponent) {
  return class extends Component {
      render() {
        return (
          <LayoutRedux>
            <WrappedComponent {...this.props} />
          </LayoutRedux>
        )
      }
  }
}
