import React, { useState } from 'react'
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { isEmpty } from 'lodash'
import { useIntl } from 'react-intl';

import './NewPlay.css'

export default function NewPlay(props) {
  const [ name, setName ] = useState("")
  const [ hint, setHint] = useState(null)
  const { onClose, open, onCreate, editPlay, onEdit } = props;
  const { formatMessage } = useIntl()

  const handleClose = () => {
    onClose();
  };

  const handleClick = () => {
    if (isEmpty(name)) {
      setHint("Script name cannot be empty")
      return
    } else {
      setHint(null)
    }

    if (editPlay) {
      onEdit(name, editPlay)
    } else {
      onCreate(name)
    }
  }

  const label = editPlay ? formatMessage({id: 'scripts.script_create_edit' }) : formatMessage({id: 'scripts.script_create_add' })

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{formatMessage({id: 'scripts.script_create_title' })}</DialogTitle>
      <div className="NewPlay-Dialog-Body">
        <TextField
          defaultValue={editPlay ? editPlay.name : null}
          id="play-naw"
          label={formatMessage({id: 'scripts.script_name' })}
          variant="outlined"
          onChange={(e) => setName(e.target.value)} style={{width:400}}
          onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleClick(name)
              }
            }}
          helperText={hint}
          error={!isEmpty(hint)}
          />
        <div className="NewPlay-Dialog-Buttons">
        <Stack spacing={2} direction="row" justifyContent="center">
          <Button variant="outlined" onClick={onClose}>{formatMessage({id: 'scripts.script_create_cancel' })}</Button>
          <Button variant="contained" onClick={() => handleClick(name)}>{label}</Button>
        </Stack>
        </div>
      </div>
    </Dialog>
  );
}

NewPlay.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
