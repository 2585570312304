import * as actionTypes from './actionTypes'

import { auth } from '../../config/firebase'

import accountsService from '../../services/accounts'

export const authSignInUser = (authResult) => {
  localStorage.setItem('uid', authResult.user.uid)
  localStorage.setItem('username', authResult.user.displayName)
  localStorage.setItem('email', authResult.user.email)

  return {
    type: actionTypes.AUTH_SIGN_IN_USER,
    authResult: authResult
  }
}

export const authSignInUserInit = (authResult) => {
  return dispach => {
    dispach(authSignInUser(authResult))
    accountsService.markSignIn(authResult.user.uid, authResult.user.displayName)
  }
}

export const logout = () => {
  return dispach => {
    auth.signOut().then(() => {
      localStorage.removeItem('uid')
      localStorage.removeItem('username')
      localStorage.removeItem('email')
      dispach(initLogout())
    })
  }
}

export const initLogout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

export const authSuccess = (uid, username, email) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    uid: uid,
    username: username,
    email: email
  }
}

export const authInitSignInUser = () => {
  return dispach => {
    const uid  = localStorage.getItem('uid')
    const username = localStorage.getItem('username')
    const email = localStorage.getItem('email')
    if (!uid) {
      dispach(logout())
    } else {
      dispach(authSuccess(uid, username, email))
    }
  }
}
