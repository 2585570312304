import { omit } from 'lodash'
import { db, toEntries, toData, deleteCollection } from '../config/firebase'

import sceneService from './scenes'

const createPlay = (uid, name) => {
  return db.collection(`accounts/${uid}/plays`)
          .doc()
          .set({ name })
          .then(() => {
            return fetchPlays(uid)
          })
}

const fetchPlays =  (uid) => {
  return db.collection(`accounts/${uid}/plays`)
  .get()
  .then(toEntries)
  .then(plays => {
    const p = plays.map(play => {
      return sceneService.fetchScenesByPlay(uid, play.id)
      .then(scenes => {
        const newPlay = {
          ...play,
          scenes: scenes
        }

        return newPlay
      })
    })
    return Promise.all(p)
  })
}

const fetchPlay = (uid, playId) => {
  return db.collection(`accounts/${uid}/plays`)
  .doc(playId)
  .get()
  .then(toData)
}

const deletePlay = (uid, play) => {
  return deleteCollection(db, `accounts/${uid}/plays/${play.id}/scenes`, 50)
  .then(() => {
    return db.collection(`accounts/${uid}/plays`)
      .doc(play.id)
      .delete()
      .then(() => {
        return play
      })
  })
}

const updatePlay = (uid, play, attributes) => {
  const newPlay = {
    ...play,
    ...attributes
  }

  return db.collection(`accounts/${uid}/plays`)
  .doc(play.id)
  .set(omit(newPlay, ['id']), {merge: true})
}

const service = {
  createPlay,
  fetchPlays,
  fetchPlay,
  deletePlay,
  updatePlay
}

export default service
