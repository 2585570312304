import * as actionTypes from './actionTypes'

const setLocale = (locale) => {
  return {
    type: actionTypes.TRANSLATIONS_LOAD_LOCALE,
    locale: locale
  }
}

export const translationsSetLocale = (locale) => dispach => {
  localStorage.setItem('locale', locale)
  dispach(setLocale(locale))
}


export const translationsLoadLocale = () => {
  const browserLocale = navigator.language;
  const locale = localStorage.getItem('locale')
  let locateToUse = null

  if (browserLocale) {
    locateToUse = browserLocale.split("-")[0]
  }

  if (locale) {
    locateToUse = locale
  }

  return {
    type: actionTypes.TRANSLATIONS_LOAD_LOCALE,
    locale: locateToUse
  }
}
