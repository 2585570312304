export const PLAYS_ON_NEW_PLAY_DIALOG_OPEN    = 'PLAYS_ON_NEW_PLAY_DIALOG_OPEN'

export const PLAYS_ON_NEW_PLAY_DIALOG_CLOSE    = 'PLAYS_ON_NEW_PLAY_DIALOG_CLOSE'

export const PLAYS_SET_VALUE    = 'PLAYS_SET_VALUE'

export const PLAY_SET_VALUE = 'PLAY_SET_VALUE'

export const PLAY_ON_NEW_SCENE_DIALOG_OPEN = 'PLAY_ON_NEW_SCENE_DIALOG_OPEN'

export const PLAY_ON_NEW_SCENE_DIALOG_CLOSE = 'PLAY_ON_NEW_SCENE_DIALOG_CLOSE'

export const SCENE_SET_VALUE = 'SCENE_SET_VALUE'

export const AUTH_SIGN_IN_USER = 'AUTH_SIGN_IN_USER'

export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const AUTH_SUCCESS = 'AUTH_SUCCESS'

export const PLAY_ON_EDIT_SCENE_SELECT = 'PLAY_ON_EDIT_SCENE_SELECT'

export const PLAYS_ON_EDIT_PLAY = "PLAYS_ON_EDIT_PLAY"

export const TRANSLATIONS_LOAD_LOCALE = "TRANSLATIONS_LOAD_LOCALE"
