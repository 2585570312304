import { isEmpty, filter } from 'lodash'

export function splitLines(t) {
  if (t === null) {
    return []
  }

  if (isEmpty(t)) {
    return []
  }


  return t.split(/\r\n|\r|\n/);
}

export function removeSymbolsFromLines(lines) {
  return lines.map(line => {
    return line.replaceAll(/[\?\!\.]/ig, "")
  })
}

export function removeSymbolsFromLine(line) {
  return line.replaceAll(/[\?\!\.]/ig, "")
}

export function cleanLines(lines) {
  return filter(lines, line => !isEmpty(line))
}
